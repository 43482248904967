import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { routes } from "../../constants";

export const getDtvUsers = createAsyncThunk("getDtvUsers", async (data) => {
  try {
    const response = await Axios.get(
      `/user?start=${data.start || 0}&limits=${data.limits || 0}`,
      authHeaders()
    );
    console.log(response.data.data);
    return response.data.data;
    // return { data: response.data, count: response.headers["x-total-count"] };
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    console.log("error:", error.response);
    return error.response.data.message;
  }
});

export const getDtvUserById = createAsyncThunk("getDtvUserById", async (data, thunkAPI) => {
  try {
    const response = await Axios.get(`/user/${data.id}`, authHeaders());
    return response.data; 
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || "Failed to fetch user details");
  }
});


const initialState = {
  dtvUserList: [],
  totalUserCount: 0,  
  userDetail: {},
  loader: false,
  updateComponent: "",
};

const dtvUserSlice = createSlice({
  name: "dtvUsers",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //DTV USER LIST
    builder.addCase(getDtvUsers.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getDtvUsers.fulfilled, (state, action) => {
      state.loader = false;
      state.dtvUserList = action.payload.rows;
      state.totalUserCount = action.payload.count;
    });
    builder.addCase(getDtvUsers.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //UPDATE DTV USER
    builder.addCase(getDtvUserById.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getDtvUserById.fulfilled, (state, action) => {
      state.loader = false;
      state.userDetail = action.payload.data; 
    });
    
    builder.addCase(getDtvUserById.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default dtvUserSlice.reducer;
