import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { tableHeadItemsForVersion } from "../../CommonComponent/TableHeads";
import { deleteIcon, userDefaultIcon } from "../../../../icons";
import { Link } from "react-router-dom";
import { checkIcon, crossIcon, editIcon, eyeIcon } from "../../../../icons";
import { routes } from "../../../../constants";

const VersionsList = ({ list, showDeleteModal, handleNavigateProfile }) => {
  const navigate = useNavigate();
  return (
    <>
      {list?.length === 0 || !list ? (
        <p className="no_data_found">No data Found</p>
      ) : (
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              {tableHeadItemsForVersion.map((item, index) => {
                return (
                  <th
                    className="custom_data_table_heading text-center"
                    key={item.id}
                  >
                    {item.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {list?.map((item, i) => {
              return (
                <tr className="custom_data_table_row" key={item.id}>
                  <td className="custom_data_table_item table_item">
                    {item.version}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.hardware}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {" "}
                    {item.description}
                  </td>
                  {/* truncate text until 4 line then show in toolkit -- */}
                  {/* <td
                    className="custom_data_table_item table_item"
                    title={item.description}
                    style={{ whiteSpace: "normal" }}
                  >
                    <div className="truncate-text">{item.description}</div>
                  </td> */}
                  <td className="custom_data_table_item table_item">
                    {item.series}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.bin}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.app_config}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.motor_config}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.created_at &&
                      moment(item.created_at).format("DD/MM/YYYY")}
                  </td>
                  <td className="custom_data_table_item table_item">
                    <button
                      aria-label="edit"
                      className="comman_btn"
                      onClick={(e) =>
                        handleNavigateProfile(item.id, item.version)
                      }
                    >
                      Profile
                    </button>
                    <div className="custom_data_table_view_edit_btn_item_row">
                      <button
                        aria-label="edit"
                        className="custom_data_table_view_edit_item_btn"
                        onClick={() =>
                          navigate(`${routes.version}/update/${item.id}`)
                        }
                      >
                        {editIcon}
                      </button>

                      <button
                        aria-label="delete"
                        className="custom_data_table_view_edit_item_btn"
                        onClick={(e) => showDeleteModal(item.id)}
                      >
                        {deleteIcon}
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}{" "}
    </>
  );
};

export default VersionsList;
