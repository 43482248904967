import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import CommonHeading from "../../component/Common/CommonComponent/CommonHeading";
import UserList from "../../component/Common/PageComponent/Users/UserList";
import { getDtvUsers, getDtvUserById } from "../../store/slice/dtvUserSlice";
import Loader from "../../component/Common/CommonComponent/Loader";
import Pagination from "../../component/Pagination/Pagination";
import ViewUserDetail from "../../component/Common/Modals/ViewUserDetail";

const UsersPage = () => {
  const dispatch = useDispatch();
  const dtvUserList = useSelector((state) => state.dtvUser.dtvUserList);
  const totalCount = useSelector((state) => state.dtvUser.totalUserCount);
  const userDetail = useSelector((state) => state.dtvUser.userDetail);
  const isLoading = useSelector((state) => state.dtvUser.loader);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!dtvUserList.length) {
      dispatch(getDtvUsers({ start: 0, limits: 10 })); 
    }
  }, [dispatch, dtvUserList.length]);

  const handleOpenModal = (userId) => {
    dispatch(getDtvUserById({ id: userId }));
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="USERS" />
        {isLoading && !dtvUserList.length ? (
          <Loader />
        ) : (
          <>
            <div className="custom_data_table_content">
              <UserList list={dtvUserList} onEditClick={handleOpenModal} />
            </div>

            {totalCount > 0 && (
              <Pagination totalRecords={totalCount} onPageChange={() => {}} />
            )}
          </>
        )}
      </div>

      <Modal show={isModalOpen} onHide={handleCloseModal} centered>
          <ViewUserDetail user={userDetail} closeModal={handleCloseModal} />
      </Modal>
    </>
  );
};

export default UsersPage;
