import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeIcon, callIcon, emailIcon } from "../../../icons"; // ✅ Import your icons
import { getDtvUserById } from "../../../store/slice/dtvUserSlice";
import Loader from "../CommonComponent/Loader";

const ViewUserDetail = ({ userId, closeModal }) => {
  const dispatch = useDispatch();

  // Fetch user details from Redux store
  const userDetail = useSelector((state) => state.dtvUser.userDetail);
  const isLoading = useSelector((state) => state.dtvUser.loader);

  useEffect(() => {
    if (userId) {
      dispatch(getDtvUserById({ id: userId }));
    }
  }, [userId]);

  return (
    <div className="modal_container">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="modal_heading">
            <h1 className="modal_main_heading">User Details</h1>
            <button className="modal_close_btn" onClick={closeModal}>
              {closeIcon}
            </button>
          </div>

          <div className="modal_detail_container">
            <div className="modal_detail_container_grid only_detail_modal">
              {/* Name */}
              <div className="modal_detail_content">
                <h2 className="modal_detail_content_heading">User Name</h2>
                <p>{userDetail?.name || "N/A"}</p>
              </div>

              {/* Email with icon */}
              <div className="modal_detail_content">
                <h2 className="modal_detail_content_heading">Email</h2>
                <p>
                  {userDetail?.email || "N/A"}
                  {userDetail?.email && (
                    <a
                      href={`mailto:${userDetail.email}`}
                      className="contact_icon"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {emailIcon}
                    </a>
                  )}
                </p>
              </div>

              {/* Phone with icon */}
              <div className="modal_detail_content">
                <h2 className="modal_detail_content_heading">Phone</h2>
                <p>
                  {userDetail?.phone || "N/A"}
                  {userDetail?.phone && (
                    <a
                      href={`tel:${userDetail.phone}`}
                      className="contact_icon"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {callIcon}
                    </a>
                  )}
                </p>
              </div>

              {/* Other details */}
              <div className="modal_detail_content">
                <h2 className="modal_detail_content_heading">City</h2>
                <p>{userDetail?.city || "N/A"}</p>
              </div>
              <div className="modal_detail_content">
                <h2 className="modal_detail_content_heading">State</h2>
                <p>{userDetail?.state || "N/A"}</p>
              </div>
              <div className="modal_detail_content">
                <h2 className="modal_detail_content_heading">Country</h2>
                <p>{userDetail?.country || "N/A"}</p>
              </div>
              <div className="modal_detail_content">
                <h2 className="modal_detail_content_heading">Role</h2>
                <p>{userDetail?.role?.name || "N/A"}</p>
              </div>
              <div className="modal_detail_content">
                <h2 className="modal_detail_content_heading">Created At</h2>
                <p>
                  {userDetail?.created_at
                    ? new Date(userDetail.created_at).toLocaleString()
                    : "N/A"}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ViewUserDetail;
