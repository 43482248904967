import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../component/Common/CommonComponent/Loader";
import { getVersionById } from "../../store/slice/versionSlice";
import AddVersionPage from "./AddVersionPage";

const UpdateVersionPage = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const versionEditDetail = useSelector((state) => state.version.versionEditDetail);
  const loading = useSelector((state) => state.version.loader);
  const error = useSelector((state) => state.version.error);

  useEffect(() => {
    dispatch(getVersionById({ id: params.id }));
  }, [params, dispatch]);

  return (
    <>
  {loading || !versionEditDetail || Object.keys(versionEditDetail).length === 0 ? (
    <Loader /> 
  ) : (
    <AddVersionPage editDetail={versionEditDetail} isEdit={true} />
  )}
</>

  );  
};

export default UpdateVersionPage;