import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";

// File upload action
export const fileUpload = createAsyncThunk(
  "fileUpload",
  async (formData, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/file/upload`,
        formData,
        authHeaders()
      );
      return response.data.key;
    } catch (error) {
      toast.error("File upload failed.", { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

// Version list
export const getVersion = createAsyncThunk("getVersion", async (data) => {
  try {
    const response = await Axios.get(
      `/version-list?start=${data.start || 0}&limits=${data.limits || 10}`,
      authHeaders()
    );
    return response.data.data;
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    return error.response.data.message;
  }
});

// Version profiles list
export const getVersionProfiles = createAsyncThunk(
  "getVersionProfiles",
  async (data) => {
    try {
      const response = await Axios.get(
        `/version-profiles/${data.data}`,
        authHeaders()
      );
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

// Batch list
export const getBatchList = createAsyncThunk("getBatchList", async (data) => {
  try {
    const response = await Axios.get(`/batch-list`, authHeaders());
    return response.data.data;
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    return error.response.data.message;
  }
});

// Add version
export const addVersion = createAsyncThunk(
  "addVersion",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(`/version`, data.data, authHeaders());
      data.onSuccess(response);
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      data.onFailure(error);
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

// delete version list
export const deleteVersionModal = createAsyncThunk(
  "deleteVersionModal",
  async (data) => {
    try {
      const response = await Axios.delete(
        `/delete-version/${data.id}`,
        authHeaders()
      );
      if (response.status === 201 && data.cb) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      console.log("error:", error.response);
    }
  }
);

//Add version profiles
export const addVersionProfiles = createAsyncThunk(
  "addVersionProfiles",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/version-profiles/${data.data.id}`,
        data.data.data,
        authHeaders()
      );
      if (response.data.message) {
        toast.success(response.data.message, {
          autoClose: 3000,
        });
      }
      data.onSuccess(response);
      return response;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      data.onFailure(error);
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

//Edit version profiles
export const editVersionProfiles = createAsyncThunk(
  "editVersionProfiles",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/version-profile/${data.data.id}`,
        data.data.data,
        authHeaders()
      );
      // if (response.data.message) {
      //   toast.success(response.data.message, {
      //     autoClose: 3000,
      //   });
      // }
      data.onSuccess(response);
      return response;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      data.onFailure(error);
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

// delete version profile
export const deleteVersionProfile = createAsyncThunk(
  "deleteVersionProfile",
  async (data) => {
    try {
      const response = await Axios.delete(
        `/version-profile/${data.data}`,
        authHeaders()
      );

      toast.success(response.data.message, {
        autoClose: 3000,
      });
      data.onSuccess(response.data);
      return response;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      data.onFailure(error);
      return error;
    }
  }
);

// get verison infomation by id
export const getVersionById = createAsyncThunk(
  "getVersionById",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/version-edit/${data.id}`,
        {}, 
        authHeaders()
      );
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

export const updateVersionById = createAsyncThunk(
  "updateVersionById",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/version-edit/${data.id}`,
        data.data,
        authHeaders()
      );

      data.onSuccess(response);
      return response.data.data;
    } catch (error) {
      // Ensure error message is properly defined
      const errorMessage = error.response?.data?.message || "Update failed!";

      // Show only one toast
      toast.error(errorMessage, { autoClose: 3000 });

      // Remove additional onFailure calls if they are also triggering a toast elsewhere
      if (data.onFailure) {
        data.onFailure(error);
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);



export const checkVersionExists = createAsyncThunk(
  "version/checkVersionExists",
  async ({ version, currentId }, thunkAPI) => {
    try {
      // Get existing versions from Redux store
      const state = thunkAPI.getState();
      const existingVersions = state.version.versionList.filter(
        (item) => item.id !== currentId // Exclude the version being edited
      ).map((item) => item.version.toLowerCase());

      return existingVersions.includes(version.toLowerCase()); // Return true if exists
    } catch (error) {
      return thunkAPI.rejectWithValue("Error checking version");
    }
  }
);


const initialState = {
  versionList: [],
  totalVersionCount: 0,
  batchList: [],
  versionProfileList: [],
  versionDetail: {},
  versionEditDetail: {},
  loader: false,
  error: null,
  updateComponent: "",
  versionExists: false, 
  userEnteredValues: null, // Store user-entered values
};

const versionSlice = createSlice({
  name: "version",
  initialState,
  reducers: {
    reqToUpdateVersionProfileList: (state, action) => {
      const findIndex = state.versionProfileList.findIndex((item) => {
        return item.id === action.payload.id;
      });
      if (findIndex === -1) return;
      state.versionProfileList.splice(findIndex, 1);
    },
    setUserEnteredValues: (state, action) => {
      state.userEnteredValues = action.payload;
    },

    resetUserEnteredValues: (state) => {
      state.userEnteredValues = null; // Clear form when a new version is added
    },

    resetVersionEditDetail: (state) => {
      state.versionEditDetail = {}; // Clear edit data when opening "Add New"
      state.userEnteredValues = null; // Clear user-entered data too
    },
  },
  extraReducers: (builder) => {
    // File Upload
    builder.addCase(fileUpload.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(fileUpload.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(fileUpload.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Version List
    builder.addCase(getVersion.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getVersion.fulfilled, (state, action) => {
      state.loader = false;
      state.versionList = action.payload.rows;
      state.totalVersionCount = action.payload.count;
    });
    builder.addCase(getVersion.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Version profile List
    builder.addCase(getVersionProfiles.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getVersionProfiles.fulfilled, (state, action) => {
      state.loader = false;
      state.versionProfileList = action.payload;
    });
    builder.addCase(getVersionProfiles.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Batch List
    builder.addCase(getBatchList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getBatchList.fulfilled, (state, action) => {
      state.loader = false;
      state.batchList = action.payload;
    });
    builder.addCase(getBatchList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Add Version
    builder.addCase(addVersion.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(addVersion.fulfilled, (state, action) => {
      state.loader = false;
      state.versionDetail = action.payload;
      state.userEnteredValues = null;
    });
    builder.addCase(addVersion.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // Delete Version
    builder.addCase(deleteVersionModal.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(deleteVersionModal.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(deleteVersionModal.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // Add Version Profiles
    builder.addCase(addVersionProfiles.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(addVersionProfiles.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(addVersionProfiles.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // Edit Version Profiles
    builder.addCase(editVersionProfiles.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(editVersionProfiles.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(editVersionProfiles.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // Delete Profile Version
    builder.addCase(deleteVersionProfile.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(deleteVersionProfile.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(deleteVersionProfile.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // Edit Version
    builder.addCase(getVersionById.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getVersionById.fulfilled, (state, action) => {
      state.loader = false;
      state.versionEditDetail = action.payload;
    });
    builder.addCase(getVersionById.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Edit Version
    builder.addCase(updateVersionById.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateVersionById.fulfilled, (state, action) => {
      state.loader = false;
      state.updateComponent = action.payload;
      state.userEnteredValues = null; 
    });
    builder.addCase(updateVersionById.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

     // Handle checkVersionExists
     builder.addCase(checkVersionExists.fulfilled, (state, action) => {
      state.versionExists = action.payload;
    })
    builder.addCase(checkVersionExists.rejected, (state) => {
      state.versionExists = false;
    });
  },
});

export const { reqToUpdateVersionProfileList, setUserEnteredValues, resetUserEnteredValues, resetVersionEditDetail } = versionSlice.actions;
export default versionSlice.reducer;
