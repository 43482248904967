import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CommonHeading from "../../component/Common/CommonComponent/CommonHeading";
import { backBtnArrow } from "../../icons";
import { useForm } from "../../hooks/useForm";
import Loader from "../../component/Common/CommonComponent/Loader";
import VersionForm from "../../component/Common/PageComponent/Version/VersionForm";
import { versionFormValidate } from "../../component/Common/PageComponent/Version/VersionCommon";
import { addVersion, checkVersionExists, getBatchList, getVersionById, updateVersionById, setUserEnteredValues, resetUserEnteredValues, resetVersionEditDetail } from "../../store/slice/versionSlice";
import { fileUpload } from "../../helpers/fileUpload";
import { toast } from "react-toastify";

const AddVersionPage = ({ editDetail, isEdit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const versionReducer = useSelector((state) => state?.version);
  const { loader, batchList } = versionReducer;

  const [isChecked, setIsChecked] = useState(editDetail?.forced || false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // Use useMemo to create a stable typeOption array
  const typeOption = useMemo(() => {
    return (batchList &&
      batchList?.map((item, index) => {
        return {
          id: item?.id,
          value: item?.batch_name,
          label: item?.batch_name,
        };
      })) ||
      [];
  }, [batchList]); // Depend only on batchList

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  const userEnteredValues = useSelector((state) => state.version.userEnteredValues);
  const versionEditDetail = useSelector((state) => state.version.versionEditDetail);

const initialFValues = {
  version: userEnteredValues?.version || editDetail?.version || "",
  hardware_batch: userEnteredValues?.hardware_batch || editDetail?.hardware || "",
  series: userEnteredValues?.series || editDetail?.series || "",
  description: userEnteredValues?.description || editDetail?.description || "",
  bin_file: userEnteredValues?.bin_file || null, 
  app_configuration: userEnteredValues?.app_configuration || null, 
  motor_configuration: userEnteredValues?.motor_configuration || null, 
  bin_file_name: userEnteredValues?.bin_file_name || editDetail?.bin || "", 
  app_configuration_name: userEnteredValues?.app_configuration_name || editDetail?.app_config || "", 
  motor_configuration_name: userEnteredValues?.motor_configuration_name || editDetail?.motor_config || "", 
  force: userEnteredValues?.forced ?? editDetail?.forced ?? false,
};


  // validation
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return versionFormValidate(fieldValues, temp, setValues, values, setErrors);
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleVersionSubmit = async () => {
    if (validate()) {
      try {
        // Store user-entered values in Redux before making an API call
        dispatch(setUserEnteredValues(values));
  
        const binFileFormData = new FormData();
        const appConfigFormData = new FormData();
        const motorConfigFormData = new FormData();
  
        if (values.bin_file) binFileFormData.append("file", values.bin_file);
        if (values.app_configuration) appConfigFormData.append("file", values.app_configuration);
        if (values.motor_configuration) motorConfigFormData.append("file", values.motor_configuration);
  
        const binFileUrl = values.bin_file ? await dispatch(fileUpload(binFileFormData)) : null;
        const appConfigUrl = values.app_configuration ? await dispatch(fileUpload(appConfigFormData)) : null;
        const motorConfigUrl = values.motor_configuration ? await dispatch(fileUpload(motorConfigFormData)) : null;
  
        const data = {
          version: values.version,
          hardware: values.hardware_batch,
          series: values.series,
          description: values.description,
          type: JSON.stringify(selectedOptions.map((item) => item.id)),
          bin: binFileUrl?.payload || values.bin_file_name || null,
          app_config: appConfigUrl?.payload || values.app_configuration_name || null,
          motor_config: motorConfigUrl?.payload || values.motor_configuration_name || null,
          forced: isChecked,
        };
  
        if (isEdit) {
          dispatch(
            updateVersionById({
              id: editDetail.id,
              data,
              onSuccess: () => {
                toast.success("Version Updated!");
                dispatch(resetUserEnteredValues());
                navigate(-1);
              },
              onFailure: (errorMessage) => {
                toast.error(errorMessage);
              },
            })
          );
        } else {
          dispatch(
            addVersion({
              data,
              onSuccess: () => {
                toast.success("New Version Added!");
                dispatch(resetUserEnteredValues());
                navigate(-1);
              },
              onFailure: (errorMessage) => {
                toast.error(errorMessage);
              },
            })
          );
        }
      } catch (error) {
        console.error("File upload failed:", error);
      }
    }
  };  
 
  useEffect(() => {
    if (!batchList || batchList.length === 0) {
      dispatch(getBatchList());
    }
  }, [dispatch, batchList]);

  useEffect(() => {
    // Reset edit version data when entering Add New Version mode
    if (!isEdit) {
      dispatch(resetVersionEditDetail());
    }
  }, [isEdit, dispatch]);

  useEffect(() => {
    if (editDetail && editDetail.type) {
      try {
        const parsedTypes = JSON.parse(editDetail.type);
        if (Array.isArray(parsedTypes)) {
          const initialSelectedOptions = parsedTypes
            .map((id) => {
              const batch = typeOption.find((option) => option.id === id);
              return batch ? batch : null;
            })
            .filter((item) => item !== null);

          setSelectedOptions(initialSelectedOptions);
        }
      } catch (error) {
        console.error("Error parsing or mapping types:", error);
        setSelectedOptions([]);
      }
    }
  }, [editDetail, typeOption]); // The key is typeOption in the dependency array

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading={isEdit ? "EDIT VERSION" : "ADD VERSION"} />
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="project_edit_main_link">
            <div className="comman_center_btn_container">
              <button className="go_back_btn" onClick={() => {
              dispatch(resetVersionEditDetail()); // Reset edit data when going back
              navigate(-1);
            }}>
                {backBtnArrow} Back
              </button>
            </div>
          </div>
          <VersionForm
            values={values}
            handleInputChange={handleInputChange}
            errors={errors}
            handleVersionSubmit={handleVersionSubmit}
            isEdit={isEdit}
            selectedOptions={selectedOptions}
            handleChange={handleChange}
            typeOption={typeOption}
            isChecked={isChecked}
            handleCheckboxChange={handleCheckboxChange}
          />
        </>
      )}
    </div>
  );
};

export default AddVersionPage;